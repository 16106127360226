import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";

import { Container, Main } from "./styled";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "../../../contexts/alert";
import { useAuth } from "../../../contexts/auth";
import { usePreloader } from "../../../contexts/preloader";
import { UsersAPI } from "../../../apis/UsersAPI";
import { RolesAPI } from "../../../apis/RolesAPI";
import { AxiosError, isAxiosError } from "axios";
import PhoneInput from "../../../components/ui/PhoneInput";
import { DeviceAPI } from "../../../apis/DeviceAPI";
import { CategoryAPI } from "../../../apis/CategoryAPI";
import { GroupsAPI } from "../../../apis/GroupsAPI";
import { ComparisonAPI } from "../../../apis/ComparisonAPI";
import { useEditAlarm } from "../../../contexts/editAlarm";
import ListWithIcon from "../../../components/ListWithIcon";

const EditAlarmProtected = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { alarmId } = useParams();
  const { authMessage, setAuthMessage, user } = useAuth();
  const { setStartPreloader } = usePreloader();
  const { editAlarmEquation, setEditAlarmEquation } = useEditAlarm();
  let params = new URLSearchParams(location.search);
  let navigation = useNavigation();
  let isEditingDevice = navigation.formData?.get("name") != null;
  let actionData = useActionData() as
    | { error: string; statusText: string; status: number }
    | undefined;
  const [message, setMessage] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);
  const [grupos, setGrupos] = useState<string[]>([]);
  const [grupoEscolhido, setGrupoEscolhido] = useState<number | undefined>(
    undefined
  );
  const [props, setProps] = useState<string>("");

  const { setMessageAlert, openAlert, setOpenAlert, setTypeAlert } = useAlert();
  const [users, setUsers] = useState<Array<any>>([]);
  const usersRef = useRef<Array<any>>([]);
  const [usersToAdd, setUsersToAdd] = useState<Array<any>>([]);
  const usersToAddRef = useRef<Array<any>>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  const [alarmTolerancia, setAlarmTolerancia] = useState<number | undefined>(
    undefined
  );
  const [alarmOperator, setAlarmOperator] = useState<string | undefined>(
    undefined
  );
  const [alarmActivated, setAlarmActivated] = useState<string | undefined>(
    undefined
  );
  const [alarmValue1, setAlarmValue1] = useState<number | undefined>(undefined);
  const [alarmValue2, setAlarmValue2] = useState<number | undefined>(undefined);
  const [alarmVerifications, setAlarmVerifications] = useState<
    number | undefined
  >(undefined);
  const [alarmMessage, setAlarmMessage] = useState<string | undefined>(
    undefined
  );
  const [alarmUsers, setAlarmUsers] = useState<Array<Number>>([]);
  const [isCreatingAlarm, setIsCreatingAlarm] = useState<boolean>(false);

  const deleteUser = useCallback(
    (id: number) => {
      console.log(usersRef.current, id);
      const filterUsers = usersRef.current.filter((u) => u.id != id);
      console.log("delete user filtered", filterUsers);
      setUsers(filterUsers);
    },
    [usersRef]
  );

  const addUser = (id: number) => {
    const filterUser = usersToAddRef.current.filter((u) => u.id === id);
    console.log(usersToAddRef.current, filterUser);
    if (filterUser.length > 0) {
      setUsers((prev) => [
        ...prev,
        {
          id: filterUser[0].id,
          icons: ["delete"],
          user: filterUser[0].nome,
          actions: [() => deleteUser(filterUser[0].id)],
        },
      ]);
    }
  };

  useEffect(() => {
    setMessage(authMessage);
    setAuthMessage(null);
  }, []);

  useEffect(() => {
    if (isEditingDevice) {
      setMessage(authMessage);
      setAuthMessage(null);
    }
  }, [isEditingDevice]);

  useEffect(() => {
    console.log("actiondata", actionData);
    if (actionData && actionData.error) {
      setAuthMessage(actionData.error);
    } else if (actionData && actionData.status == 200) {
      setAuthMessage("Alarme atualizado com sucesso");
      navigate(`/`);
    } else {
      if (actionData) {
        setAuthMessage(`status: ${actionData.status}`);
      }
    }
  }, [actionData, data]);

  useEffect(() => {
    setStartPreloader(true);
    ComparisonAPI.getComparisonId({
      token: user?.token,
      id: alarmId,
    })
      .then((res: any) => {
        let usersTemp: Array<any> = [];
        res.users.map((u: any, index: number) => {
          usersTemp.push({
            id: u.id,
            icons: ["delete"],
            user: u.name,
            actions: [() => deleteUser(u.id)],
          });
        });
        console.log("usersTemp", usersTemp);
        setUsers(usersTemp);
        setData(res);
      })
      .catch((err: Error | AxiosError) => {
        console.log(err);
      })
      .finally(() => {
        setStartPreloader(false);
      });
  }, []);

  useEffect(() => {
    if (editAlarmEquation === "") {
      navigate(-1);
    }
    console.log("edit alarm data", data);
    if (data && data.operation) {
      setAlarmOperator(data.operation);
    }
  }, [data, editAlarmEquation]);

  useEffect(() => {
    if (isCreatingAlarm) {
      setStartPreloader(true);
    } else {
      setStartPreloader(false);
    }
  }, [isCreatingAlarm]);

  useEffect(() => {
    //search user
    if (userSearch !== "") {
      UsersAPI.searchUser({ token: user?.token, search: userSearch })
        .then((res: any) => {
          const filteredUser = res.data.map((usu: any) => ({
            id: usu.id,
            nome: usu.name,
            "e-mail": usu.email,
            cel: usu.phone,
            icons: ["add"],
            actions: [() => addUser(usu.id)],
          }));

          const filteredUserFinal = filteredUser.filter((u: any) => {
            let result = true;
            for (let i = 0; i < users.length; i++) {
              if (u.id === users[i].id) {
                result = false;
              }
            }
            return result;
          });

          console.log("filteredUserFinal", filteredUserFinal, users);

          setUsersToAdd(filteredUserFinal);
          usersToAddRef.current = filteredUserFinal;
        })
        .catch((err: Error | AxiosError) => {
          console.log(err);
        });
    } else {
      setUsersToAdd([]);
    }
  }, [userSearch, users]);

  useEffect(() => {
    if (users && users.length > 0) {
      let usersTemp: Array<number> = [];
      users.map((u, index) => usersTemp.push(u.id));
      setAlarmUsers(usersTemp);
      usersRef.current = users;
      console.log("users 214", users);
    }
  }, [users]);

  return (
    <Container>
      <Main>
        {data && (
          <Card color="transparent" shadow={false}>
            <Form
              method="post"
              autoComplete="new-password"
              replace
              className="mt-8 mb-2 w-full max-w-4xl flex flex-col flex-nowrap items-center"
            >
              <div className="mb-1 flex flex-col w-full gap-6">
                <>
                  <input
                    name="is_range"
                    type="number"
                    value={data.is_range}
                    hidden
                    readOnly
                  />
                  <input name="id_1" value={data.id_1} hidden readOnly />
                  <input name="id_2" value={data.id_2} hidden readOnly />
                  <input
                    name="prop_id1"
                    value={data.prop_id1}
                    hidden
                    readOnly
                  />
                  <input
                    name="prop_id2"
                    value={data.prop_id2}
                    hidden
                    readOnly
                  />
                  <input
                    name="attr_id1"
                    value={data.attr_id1}
                    hidden
                    readOnly
                  />
                  <input
                    name="attr_id2"
                    value={data.attr_id2}
                    hidden
                    readOnly
                  />
                  <input name="id" value={alarmId} hidden readOnly />
                  <Typography variant="h6" className="-mb-3 ">
                    Ativado
                  </Typography>
                  <Select
                    label="Alarme ativado"
                    value={data.enabled.toString()}
                    onChange={(val) => {
                      setAlarmActivated(val);
                    }}
                  >
                    {["true", "false"].map((opcao: string) => (
                      <Option key={opcao} value={opcao}>
                        {opcao === "true" ? "sim" : "não"}
                      </Option>
                    ))}
                  </Select>
                  <input
                    name="enabled"
                    value={alarmActivated === "true" ? "1" : "0"}
                    hidden
                    readOnly
                  />
                  <Typography variant="h6" className="mb-3 ">
                    Equação
                  </Typography>
                  <Textarea
                    key={editAlarmEquation}
                    value={editAlarmEquation.replace(/  +/g, "")}
                    label="Equation"
                    disabled
                    className="h-[60vh] flex text-left"
                  ></Textarea>
                  {data.is_range === 0 && (
                    <>
                      <Typography variant="h6" className="-mb-3 ">
                        Operador
                      </Typography>
                      <Select
                        label="Operador"
                        value={data.operation}
                        onChange={(val) => {
                          setAlarmOperator(val);
                        }}
                      >
                        {["<", "<=", "==", "!=", ">=", ">"].map(
                          (operator: string) => (
                            <Option key={operator} value={operator}>
                              {operator}
                            </Option>
                          )
                        )}
                      </Select>
                      <input
                        name="operation"
                        value={alarmOperator}
                        hidden
                        readOnly
                      />
                    </>
                  )}
                  {data.is_range === 1 && (
                    <>
                      <Typography variant="h6" className="-mb-3 ">
                        Valor mínimo
                      </Typography>
                      <Input
                        name="min"
                        type="number"
                        autoComplete="new-password"
                        size="lg"
                        placeholder="Valor minimo"
                        className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        defaultValue={data.min}
                      />
                      <Typography variant="h6" className="-mb-3 ">
                        Valor máximo
                      </Typography>
                      <Input
                        name="max"
                        type="number"
                        autoComplete="new-password"
                        size="lg"
                        placeholder="Valor máximo"
                        className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        defaultValue={data.max}
                      />
                    </>
                  )}

                  <Typography variant="h6" className="-mb-3 ">
                    Tolerância
                  </Typography>
                  <Input
                    name="tolerance"
                    type="number"
                    autoComplete="new-password"
                    size="lg"
                    placeholder="Tolerância"
                    className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    defaultValue={data.tolerance}
                  />
                  <Typography variant="h6" className="-mb-3 ">
                    Número de Verificações
                  </Typography>
                  <Input
                    name="threshold"
                    type="number"
                    autoComplete="new-password"
                    size="lg"
                    placeholder="Verificações"
                    className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    defaultValue={data.threshold}
                  />
                  <Typography variant="h6" className="-mb-3 ">
                    Mensagem
                  </Typography>
                  <Input
                    name="mensagem"
                    type="text"
                    autoComplete="new-password"
                    size="lg"
                    placeholder="Mensagem"
                    className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    defaultValue={data.message}
                  />
                  <Card className=" w-[100%] p-5 flex flex-row flex-wrap align-middle justify-center gap-6">
                    <Typography
                      variant="h6"
                      className="mb-4 flex flex-row  w-[100%]"
                    >
                      Adicionar Usuários
                    </Typography>
                    <Input
                      label="Buscar Usuário"
                      name="buscar-usuario"
                      type="text"
                      autoComplete="off"
                      size="lg"
                      onChange={({ target }) => {
                        setUserSearch(target.value);
                      }}
                    />
                    <ListWithIcon items={usersToAdd} />
                    <Typography
                      variant="h6"
                      className=" flex flex-row  w-[100%]"
                    >
                      Usuários adicionados
                    </Typography>
                    {<ListWithIcon items={users} />}
                    <input
                      name="users"
                      value={users.map((us) => us.id)}
                      hidden
                      readOnly
                    />
                  </Card>
                </>
              </div>
              <Button
                type="submit"
                disabled={isEditingDevice}
                className="mt-6 bg-secondary "
                fullWidth
              >
                {isEditingDevice ? "Atualizando" : "Atualizar"}
              </Button>
              <Button
                className="mt-6 bg-transparent text-secondary"
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            </Form>
          </Card>
        )}
      </Main>
    </Container>
  );
};

export default EditAlarmProtected;
