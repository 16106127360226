import { useState } from "react";
import {
  PencilIcon,
  UserPlusIcon,
  XCircleIcon,
  ChartBarIcon,
  PlusCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid";
import {
  List,
  ListItem,
  ListItemSuffix,
  Card,
  IconButton,
  Typography,
  ListItemPrefix,
  Tooltip,
} from "@material-tailwind/react";

interface ListWithDeleteProps {
  items: Array<any>;
}

export default function ListWithIcon({ items }: ListWithDeleteProps) {
  return (
    <List className="p-0">
      {items.map((item) => (
        <ListItem ripple={false} className="p-0">
          <table className="border  w-full  table-auto text-center">
            <thead>
              <tr>
                {Object.keys(item).map((it) =>
                  it !== "icons" && it !== "actions" ? (
                    <th
                      className={`border border-blue-gray-100 bg-blue-gray-50 p-1 ${
                        it === "equation"
                          ? "w-[20%]"
                          : it === "message"
                          ? "w-[30%]"
                          : it === "id"
                          ? "w-[9%]"
                          : ""
                      }`}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {it === "enabled"
                          ? "Ativo"
                          : it === "equation"
                          ? "Equação"
                          : it === "message"
                          ? "Mensagem"
                          : it === "tolerance"
                          ? "Tol."
                          : it === "threshold"
                          ? "Ver."
                          : it === "users"
                          ? "Usuário"
                          : it === "user"
                          ? "Usuário"
                          : it}
                      </Typography>
                    </th>
                  ) : (
                    <></>
                  )
                )}
                <th className="border border-blue-gray-100 bg-blue-gray-50 p-1 w-[5%]">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    Ações
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {Object.keys(item).map((it) =>
                  it !== "icons" &&
                  it !== "actions" &&
                  it !== "users" &&
                  it !== "enabled" ? (
                    <td className={"p-4 border-b border-blue-gray-50"}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal whitespace-pre-line"
                      >
                        {(item as any)[it]}
                      </Typography>
                    </td>
                  ) : it === "users" ? (
                    <td className={"p-4 border-b border-blue-gray-50"}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        <ul>
                          {(item as any)[it].map(
                            (usuario: any, index: number) => (
                              <li>
                                {index + 1}. {usuario.name}
                              </li>
                            )
                          )}
                        </ul>
                      </Typography>
                    </td>
                  ) : it === "enabled" ? (
                    <td className={"p-4 border-b border-blue-gray-50"}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {(item as any)[it] === true ? (
                          <CheckCircleIcon
                            aria-label={"não"}
                            className="h-6 w-6 text-secondary"
                          />
                        ) : (
                          <XCircleIcon
                            aria-label={"não"}
                            className="h-6 w-6 text-gray-500"
                          />
                        )}
                      </Typography>
                    </td>
                  ) : (
                    <></>
                  )
                )}
                <td>
                  {item.icons.map((icon: any, index: number) => (
                    <IconButton
                      variant="text"
                      className={
                        icon === "delete"
                          ? "text-danger"
                          : icon === "edit"
                          ? "text-secondary"
                          : icon === "add"
                          ? "text-blue-900"
                          : "text-gray-800"
                      }
                      onClick={() =>
                        icon === "edit"
                          ? item.actions[index](item.id, item.equation)
                          : item.actions[index](item.id)
                      }
                      size="lg"
                    >
                      <Tooltip content={icon}>
                        {icon === "delete" ? (
                          <XCircleIcon aria-label={icon} className="h-6 w-6" />
                        ) : icon === "add" ? (
                          <PlusCircleIcon
                            aria-label={icon}
                            className="h-6 w-6"
                          />
                        ) : icon === "edit" ? (
                          <PencilIcon aria-label={icon} className="h-4 w-4" />
                        ) : (
                          <></>
                        )}
                      </Tooltip>
                    </IconButton>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </ListItem>
      ))}
    </List>
  );
}
